import { useState, useEffect, useContext } from "react"
import { withStyles } from "@material-ui/core/styles"
import { Checkbox, Divider, Tooltip, FormControlLabel, Button } from "@material-ui/core"
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
import { getBblockedBedsReasson, getBusyBeds } from "../../services/beds"
import clsx from "clsx"
import { format } from "date-fns"
import DrawerForBeds from "../DrawerForBeds"
import ReserveModal from "../ReserveModal"
import DashboardUnit from "../../contexts/dashboardUnitContext"
import useStyles from "./styles"
import "../../styles/cardBeds.css"
import DischargeDateModal from "../DischargeDateModal"
import { svgError, svgCheck } from "../../utils/svgHelper"
import UpdateIcon from "@material-ui/icons/Update"

export default function CardBeds({ data, reloadChart, getBeds }) {
  const classes = useStyles()
  const {
    hasInterconsultations,
    hasTreatments,
    patientRut,
    admissionCode,
    bedLastChangedDate,
    code,
    covid19,
    grd,
    isapre,
    isaprePlan,
    patientDischargeDate,
    patientName,
    prestationCode,
    professionalName,
    sex,
    stateDescription,
    stateId,
    strAge,
    strStayTime,
    urgencyLaw,
    stable,
    prestationDesc,
  } = data || {}
  const [open, setOpen] = useState(false)
  const [dataDrawer, setDataDrawer] = useState([])
  const [dischargeDate, setDischargeDate] = useState(patientDischargeDate)
  const [openModal, setOpenModal] = useState(false)
  const [currentReserve, setCurrentReserve] = useState(null)
  const [openDischargeDateModal, setOpenDischargeDateModal] = useState(false)

  const isVirtual = data && data.isVirtual

  const onCloseDischargeDateModal = () => {
    setOpenDischargeDateModal(false)
  }

  useEffect(() => {
    if (stateId === 2) {
      loadReasonsBeds()
    }
    if (stateId === 3) {
      loadProcessBeds()
    }
  }, [])

  const loadReasonsBeds = async () => {
    try {
      const resp = await getBblockedBedsReasson(code)
      reloadChart()
      setDataDrawer(resp.reassons)
    } catch (e) {
      console.log(e)
    }
  }

  const loadProcessBeds = async () => {
    try {
      const resp = await getBusyBeds(code, admissionCode)
      setDataDrawer(resp.dischargeProcedures)
      setDischargeDate(resp.dischargeDate)
    } catch (e) {
      console.log(e)
    }
  }

  const handleOpenDrawer = () => {
    if (stateId === 2 || stateId === 3) {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const convertDate = (inputFormat) => {
    function pad(s) {
      return s < 10 ? "0" + s : s
    }
    var d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/")
  }

  const handleFormatDate = (date) => {
    const formatDate = format(new Date(date), "dd-MM-yyyy")
    return formatDate
  }

  const onOpenModal = () => {
    setOpenModal(true)
  }

  const onCloseModal = () => {
    setOpenModal(false)
  }

  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: 13,
    },
  }))(Tooltip)

  const CardBedBusyAndPending = ({ type }) => {

    const [showMore, setShowMore] = useState(false)

    const handleShowMore = ()=>{ 
      setShowMore(!showMore)
    }

    const handleHeight = () =>{
      if(showMore){
       return "245px"
      }

      if(stateId === 3 && !prestationDesc){
        return "650px"
      }

      if(stateId === 3 && prestationDesc){
        return "710px"
      }

      return "inherit"

    }

    return (
      <div
        className={classes.card}
        style={{
          height: handleHeight,
        }}
      >
        <div className={classes.contentCode}>
          <p className={classes.textData}>{code}</p>
          <p className={classes.textData}>{!!patientRut && patientRut}</p>
        </div>
        <Divider className={classes.divider} />
        <div>
          <div
            className={clsx(classes.contentText, type === 3 ? classes.stateBusy : classes.statePending)}
            onClick={() => handleOpenDrawer()}
          >
            <p>{stateDescription}</p>
            <p>{admissionCode}</p>
          </div>
          <div className={classes.contentText}>
            <div style={{ display:'flex', width:'100%', justifyContent:'space-between'}}>
              <Button style={{ textTransform:'none', color:'green'}} onClick={handleShowMore}> 
                { showMore ? "Ver menos (-)" : "Ver más (+)" }
              </Button>
              <div>
                <CustomTooltip
                  arrow
                  title={`Interconsulta ${!hasInterconsultations ? "" : hasInterconsultations}`}
                  placement="top"
                >
                  <Checkbox color="primary" checked={!!hasInterconsultations} />
                </CustomTooltip>
                <CustomTooltip arrow title={`Tratancia ${!hasTreatments ? "" : hasTreatments}`} placement="top">
                  <Checkbox color="primary" checked={!!hasTreatments} />
                </CustomTooltip>
              </div>
            </div>
          </div>
          {(!!grd || !!isapre || !!isaprePlan) && (
            <div className={classes.contentText}>
              <p>Planes</p>
              <div style={{ paddingLeft: "1rem", fontSize: "0.9rem" }}>
                <p style={{ fontSize: "0.9rem" }}>{!!grd && grd}</p>
                <p style={{ fontSize: "0.9rem" }}>{`${!!isapre && isapre} - ${!!isaprePlan && isaprePlan}`}</p>
              </div>
            </div>
          )}
          <div className={classes.contentText}>
            <p>Estadía</p>
            <p className={classes.textData}>{strStayTime}</p>
          </div>
            {
              stable ? (
                <div className={classes.contentText}>
                <p> Ley de Urgencía </p>
                {
                  stable === "SI" ? 
                  <div className={classes.divUrgencyLawGreen}>
                    <p className={classes.urgencyLaw}>LU Estabilizado</p>
                  </div>
                  :
                  <div className={classes.divUrgencyLawRed}>
                    <p className={classes.urgencyLaw}>LU NO Estabilizado</p>
                  </div>
                }
                </div>
              ) : null
            }
        </div>

        <Divider className={classes.divider} />
        { showMore &&
        <>
          <div className={classes.contentText}>
            <p>Último movimiento</p>
            <p className={classes.textData}>{!bedLastChangedDate ? "-" : handleFormatDate(bedLastChangedDate)}</p>
          </div>
          <div
            className={classes.contentText}
            onClick={() => {
              if (stateId === 2 || stateId === 3) {
                setOpenDischargeDateModal(true)
              }
            }}
          >
            <p>Fecha próxima de alta</p>
            <p className={classes.textData}>{!dischargeDate ? "-" : handleFormatDate(dischargeDate)}</p>
          </div>
          <div className={clsx(classes.contentText, classes.reserve)} onClick={onOpenModal}>
            <p>Cama reservada</p>
            <p className={classes.textData}>{!currentReserve ? "-" : "SI"}</p>
          </div>
          <Divider className={classes.divider} />
          <div>
            <p className="name mt16">
              {patientName} <span>{`(${sex})`}</span>
            </p>
          </div>
          <div className={classes.contentText}>
            <p>Edad</p>
            <p className={classes.textData}>{strAge}</p>
          </div>
          {!!covid19 && (
            <div className={classes.contentText}>
              <p>Covid19</p>
              <p>{covid19}</p>
            </div>
          )}
          <div className={classes.contentText}>
            <p>DR(A)</p>
            <p className={classes.textData}>{professionalName}</p>
          </div>
          <div className={classes.contentText}>
            <p>Tratancias:</p>
            <p className={classes.textData}>{hasTreatments}</p>
          </div>
          <div className={classes.contentText}>
            <p>Interconsultas:</p>
            <p className={classes.textData}>{hasInterconsultations}</p>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className={classes.footer}>
              <p>Hosp. Individual</p>
              <p className={classes.textData}>{prestationCode}</p>
            </div>

            {prestationDesc ? (
              <div className={classes.footer} style={{ marginTop: "10px" }}>
                <p>Prestación</p>
                <p className={classes.textData}>{prestationDesc}</p>
              </div>
            ) : null}
          </div>
        </>}
      </div>
    )
  }

  const renderReasons = () => {
    const tag = dataDrawer.filter((el) => el.selected)
    const reason =
      tag.length > 0
        ? tag.map((el) => <FormControlLabel key={el.id} control={<Checkbox checked={el.selected} />} label={el.name} />)
        : "-"
    return reason
  }

  const CardBedBlockedAndAvailable = ({ type }) => {
    return (
      <div className={classes.card} style={{ height: 255 }}>
        <div className={classes.contentCode}>
          <p className={classes.textData}>{code}</p>
          <p className={classes.textData}>{!!patientRut && patientRut}</p>
        </div>
        <Divider className={classes.divider} />
        <div
          className={clsx(classes.contentText, type === 1 ? classes.stateAvailable : classes.stateBlocked)}
          onClick={() => handleOpenDrawer()}
        >
          <p>{!stateDescription ? "-" : stateDescription}</p>
          <p>{!admissionCode ? "-" : admissionCode}</p>
        </div>
        <div className={classes.contentText}>
          <p>Último movimiento</p>
          <p className={classes.textData}>{!bedLastChangedDate ? "-" : convertDate(bedLastChangedDate)}</p>
        </div>
        <div className={clsx(classes.contentText, classes.reserve)} onClick={onOpenModal}>
          <p>Cama reservada</p>

          <p className={classes.textData}>{currentReserve === null ? "-" : "SI"}</p>
        </div>
        <div className={classes.contentReasons}>{dataDrawer && type === 2 && renderReasons()}</div>
      </div>
    )
  }

  const CardBedInTransit = ({ type }) => {
    return (
      <div className={classes.card}>
        <div className={classes.contentCode}>
          <p className={classes.textData}>{code}</p>
          <p className={classes.textData}>{!!patientRut && patientRut}</p>
        </div>
        <Divider className={classes.divider} />
        <div className={clsx(classes.contentText, classes.stateInTransit)} onClick={() => handleOpenDrawer()}>
          <p>{!stateDescription ? "-" : stateDescription}</p>
          <p>{!admissionCode ? "-" : admissionCode}</p>
        </div>
        <div className={classes.contentText}>
          <p>Último movimiento</p>
          <p className={classes.textData}>{!bedLastChangedDate ? "-" : convertDate(bedLastChangedDate)}</p>
        </div>
        <div className={clsx(classes.contentText, classes.reserve)} onClick={onOpenModal}>
          <p>Cama reservada</p>

          <p className={classes.textData}>{currentReserve === null ? "-" : "SI"}</p>
        </div>
        <div className={classes.contentReasons}>{dataDrawer && type === 2 && renderReasons()}</div>
      </div>
    )
  }

  const renderCardAccordingState = () => {
    switch (stateId) {
      case -1:
        return <CardBedBlockedAndAvailable type={-1} />
      case 1:
        return <CardBedBlockedAndAvailable type={1} />
      case 2:
        return <CardBedBlockedAndAvailable type={2} />
      case 3:
        return <CardBedBusyAndPending type={3} />
      case 4:
        return <CardBedBusyAndPending type={4} />
      case 5:
        return <CardBedInTransit type={5} />
      default:
        break
    }
  }

  return (
    <>
      <div>{renderCardAccordingState()}</div>
      <ReserveModal
        stateId={stateId}
        open={openModal}
        onClose={onCloseModal}
        setCurrentReserve={setCurrentReserve}
        code={code}
        currentReserve={currentReserve}
        admissionCode={admissionCode}
      />
      <DrawerForBeds
        open={open}
        onClose={handleClose}
        code={code}
        admissionCode={admissionCode}
        stateId={stateId}
        reload={stateId === 2 ? loadReasonsBeds : loadProcessBeds}
      />
      <DischargeDateModal
        code={code}
        stateId={stateId}
        open={openDischargeDateModal}
        onClose={onCloseDischargeDateModal}
        admissionCode={admissionCode}
        reload={stateId === 2 ? loadReasonsBeds : loadProcessBeds}
      />
    </>
  )
}
